@import "../../Config/variables.scss";
.hero-section {
  padding: 142px 0 166px;
  background-color: $GrayBG;
  overflow: hidden;
  @media #{$LaptopLg} {
    padding: 140px 0 160px;
  }
  @media #{$Laptop} {
    padding: 120px 0 80px;
  }
  @media #{$Ipad} {
    padding: 120px 0 60px;
  }
  @media #{$PhoneL} {
    padding: 120px 0 40px;
    
  }
  &__inner {
    display: flex;
    align-items: center;
    @media #{$Ipad} {
      flex-direction: column;
    }
    .hero-left {
      flex: 0 1 700px;
      padding-right: 13px;
      @media #{$Ipad} {
        flex: 1 1 auto;
        padding: 0 0 20px ;
      }
      h1 {
        margin-bottom: 49px;
        @media #{$Ipad} {
          margin-bottom: 30px;
        }
        @media #{$PhoneL} {
          margin-bottom: 20px;
        }
        .highlights {
          color: $primary;
          position: relative;
          white-space: nowrap;
          .underline {
            position: absolute;
            bottom: -20px;
            left: 47%;
            transform: translate(-50%, 0);
            @media #{$PhoneL} {
              max-width: 130px;
              bottom: -11px;
            }
          }
        }
      }
      p {
        margin-bottom: 40px;
        @media #{$Ipad} {
          margin-bottom: 20px;
        }
      }
    }
    .hero-right {
      flex: 0 1 620px;
      position: relative;
      @media #{$Ipad} {
        flex: 1 1 auto;
        width: 100%;
        text-align: center;
      }
      img {
          position: relative;
          z-index: 2;
          max-width: 100%;
          @media #{$Ipad} {
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            max-width: 100%;
            position: absolute;
          }
          &.hero-bg-pattern {
              position: absolute;
              z-index: 1;
              top: -25px;
              left: -300px;
              max-width: inherit;
              width: 1181px;
              @media #{$Laptop} {
                left: -351px;
              }
              @media #{$Ipad} {
                position: relative;
                left: 0;
                top: 0;
                width: 100%;
                max-width: 100%;
              }
          }
      }
    }
  }
}
