@import '../../Config/variables.scss';
.modelsCrad {
    background:$LightPattensBlue;
    border-radius: 24px;
    padding: 64px 93px;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    &:last-child {
        margin-bottom: 114px;
        @media #{$LaptopLg} {
            margin-bottom: 50px;
        }
        @media #{$PhoneL} {
            margin-bottom: 20px;
        }
    }
    @media #{$Laptop} {
        padding: 30px;
    }
    @media #{$PhoneL} {
        flex-wrap: wrap;
    }
    @media #{$PhoneL} {
        margin-bottom: 20px;
    }
    .modelsCradIcon {
        width: 280px;
        max-width: 280px;
        flex: 0 0 280px;
        img {
            @media #{$Ipad} {
                width: 220px;
            }
        }
        @media #{$PhoneL} {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: 25px;
            text-align: center;
        }
    }
    .modelsCradContent {
        width: calc(100% - 280px);
        padding-left: 200px;
        @media #{$LaptopLg} {
            padding-left: 150px;
        }
        @media #{$Laptop} {
            padding-left: 100px;
        }
        @media #{$Ipad} {
            padding-left: 20px;
        }
        @media #{$PhoneL} {
            width: 100%;
            max-width: 100%;
            flex: 0 0 100%;
            padding: 0;
            text-align: center;
        }
        h4 {
            margin-bottom: 24px;
            @media #{$PhoneL} {
                margin-bottom: 15px;
            }
        }
        p {
            max-width: 614px;
            margin: 0;
            @media #{$PhoneL} {
                margin: 0 auto;
            }
        }
    }
    &:nth-child(even) {
        flex-direction: row-reverse;
        .modelsCradContent {
            padding-right: 200px;
            padding-left: 0px;
            @media #{$LaptopLg} {
                padding-right: 150px;
            }
            @media #{$Laptop} {
                padding-right: 100px;
            }
            @media #{$Ipad} {
                padding-right: 20px;
            }
            @media #{$PhoneL} {
                padding-right: 0px;
            }
            p {
                max-width: 627px;
                @media #{$PhoneL} {
                    margin: 0 auto;
                }
            }
        }
        .modelsCradIcon {
            text-align: right;
            @media #{$PhoneL} {
                text-align: center;
            }
        }
    }
}