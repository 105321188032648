@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@import "./Config/variables.scss";
@import "~bootstrap/scss/bootstrap";

body {
  font-size: 20px;
  line-height: 24px;
  @media #{$Laptop} {
    font-size: 18px;
    line-height: 22px;
  }
  @media #{$PhoneL} {
    font-size: 16px;
  }
}
.btn {
  @media #{$LaptopLg} {
    padding-left: 26px;
    padding-right: 26px;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 18px;
    line-height: 22px;
    border-radius: 8px;
  } 
}  
h1 {
  font-weight: 700;
  font-size: 52px;
  line-height: 68px;
  color: $PrussianBlue;
  margin-bottom: 20px;
  @media #{$LaptopLg} {
    font-size: 48px;
    line-height: 62px;
  }
  @media #{$Laptop} {
    font-size: 45px;
    line-height: 60px;
  }
  @media #{$Ipad} {
    font-size: 42px;
    line-height: 58px;
  }
  @media #{$PhoneL} {
    font-size: 40px;
    line-height: 52px;
  }
}

h2 {
  font-weight: 700;
  font-size: 56px;
  line-height: 60px;
  color: $PrussianBlue;
  margin-bottom: 15px;
  @media #{$LaptopLg} {
    font-size: 42px;
    line-height: 50px;
  }
  @media #{$Laptop} {
    font-size: 40px;
    line-height: 50px;
  }
  @media #{$Ipad} {
    font-size: 36px;
    line-height: 40px;
  }
  @media #{$PhoneL} {
    font-size: 30px;
    line-height: 34px;
  }
}
h3 {
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
  color: $DarkBlack;
  margin-bottom: 15px;
  @media #{$LaptopLg} {
    font-size: 28px;
    line-height: 34px;
  }
  @media #{$Laptop} {
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 10px;
  }
  @media #{$Ipad} {
    font-size: 20px;
    line-height: 28px;
  } 
}
h4 {
  font-weight: 700;
  font-size: 42px;
  line-height: 51px;
  color: $PrussianBlue;
  margin-bottom: 15px;
  @media #{$LaptopLg} {
    font-size: 36px;
    line-height: 44px;
  }
  @media #{$Laptop} {
    font-size: 32px;
    line-height: 41px;
    margin-bottom: 10px;
  }
  @media #{$Ipad} {
    font-size: 30px;
    line-height: 38px;
  }
  @media #{$PhoneL} {
    font-size: 28px;
    line-height: 36px;
  }
}
p {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: $SlateGrey;
  margin-bottom: 15px;
  @media #{$LaptopLg} {
    font-size: 18px;
    line-height: 24px;
  }
  @media #{$Laptop} {
    font-size: 16px;
    line-height: 24px;
  }
}

img {
  max-width: 100%;
}



.arrowCircle {
  position: fixed;
  transform: translate(-50%, -50%);
  top: 0;
  left: 0;
  opacity: 0.3;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  z-index: 9999;
  &.sm {
    width: 30px;
    height: 30px;
  }
  &.md {
    width: 60px;
    height: 60px;
  }
  &.lg {
    width: 90px;
    height: 90px;
  }
}