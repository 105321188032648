@import '../../Config/variables.scss';

.hmModels {
    padding: 110px 0 79px;
    background: $White;
    overflow: hidden;
    @media #{$LaptopLg} {
        padding: 65px 0;
    }
    @media #{$Ipad} {
        padding: 60px 0;
    }
    @media #{$PhoneSm} {
        padding:40px 0;
    }
    .pageTitle {
        margin-bottom: 48px;
        width: 100%;
        text-align: center;
        @media #{$PhoneL} {
            margin-bottom: 30px;
        }
    }
    .modelsInner {
        position: relative;
        .modelsConsultation {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: 0;
            background: $AliceBlueLight2;
            border-radius: 20px;
            padding: 10px 18px 0 40px;
            @media #{$Laptop} {
                align-items: flex-end;
            }
            @media #{$PhoneL} {
                padding: 0 15px 0;
            }
            .modelsConsultationContent {
                width: 622px;
                max-width: 622px;
                flex: 0 0 622px;
                padding-right:15px;
                @media #{$Laptop} {
                    padding:0 0 15px 0;
                }
                @media #{$Ipad} {
                    width: 380px;
                    max-width: 380px;
                    flex: 0 0 380px;
                }
                @media #{$PhoneL} {
                    width: 100%;
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding:15px 0 30px;
                    text-align: center;
                }
                h2 {
                    margin-bottom: 20px;
                }
                p {
                    margin-bottom: 50px;
                    @media #{$PhoneL} {
                        margin-bottom: 25px;
                    }
                }
            }
            .modelsConsultationImg {
                width: calc(100% - 622px);
                padding-left:15px;
                text-align: right;
                @media #{$Ipad} {
                    width: calc(100% - 380px);
                }
                @media #{$PhoneL} {
                    width: 100%;
                    padding: 0;
                    text-align: center;
                }
                img {
                    margin: -2px 0 0;
                }
            } 
        }

        .modelsInnerCrad {
            display: flex;
            flex-direction: column;
            // .modelsCradRow {
            //     margin-bottom: 50px;
            //     &:last-child {
            //         margin-bottom: 114px;
            //     }
            // }
        }
    }
}