@import "./../../Config/variables.scss";
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;
  transition: all 0.5s ease-in-out;
  z-index: 99;
  @media #{$LaptopLg} {
    padding: 20px 0;
  }
  @media #{$Laptop} {
    padding: 20px 0;
  }
  
  &.header-sticky {
    padding: 10px 0;
    background-color: $White;
    box-shadow: 0 -2px 8px 1px rgba($color: $PrussianBlue, $alpha: 0.3);
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &_logo {
      flex: 0 0 250px;
      @media #{$LaptopLg} {
        flex:0 0 180px;
      }
      @media #{$PhoneSm} {
        flex: 0 0 120px;
      }
      
      img {
        max-width: 100%;
      }
    }
    &_navbar {
      flex: 0 0 auto;
      @media #{$Ipad} {
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        overflow: auto;
        background-color: $GrayBG;
        box-shadow: -2px 0 8px 1px rgba($color: $PrussianBlue, $alpha: 0.3);
        height: 100vh;
        z-index: 100; 
        padding: 30px 0;
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        pointer-events: none;
        transition: transform 0.8s cubic-bezier(0.9,0,0.1,1);
        -webkit-transition: transform 0.8s cubic-bezier(0.9,0,0.1,1);
      }
      &.open {
        pointer-events: all;
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none; 
        .close-btn-row {
          .close-btn {
            transition-delay: 0.2s;
            transform: none;
            -webkit-transform: none;
            -moz-transform: none;
            -ms-transform: none;
            -o-transform: none;
            animation: closemenubtn 1s cubic-bezier(0.9,0,0.5,1.2);
            -webkit-animation: closemenubtn 1s cubic-bezier(0.9,0,0.5,1.2);
            animation-delay: 0.2s;
            transition: transform 0.25s cubic-bezier(0.8,0,0.2,1);
            -webkit-transition: transform 0.25s cubic-bezier(0.8,0,0.2,1);
            -moz-transition: transform 0.25s cubic-bezier(0.8,0,0.2,1);
            -ms-transition: transform 0.25s cubic-bezier(0.8,0,0.2,1);
            -o-transition: transform 0.25s cubic-bezier(0.8,0,0.2,1);
          }
        } 
      }
      .close-btn-row {
        display: none;
        padding: 0 20px 60px;
        text-align: right;
        @media #{$Ipad} {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .menu-logo {
          max-width: 150px; 
        }
        .close-btn {
          border: 0;
          padding: 0;
          background-color: transparent;
          transform: translateX(200px) rotate(360deg);
          -webkit-transform: translateX(200px) rotate(360deg);
          -moz-transform: translateX(200px) rotate(360deg);
          -ms-transform: translateX(200px) rotate(360deg);
          -o-transform: translateX(200px) rotate(360deg);
          transition: transform 1s cubic-bezier(0.9,0,0.5,1.2);
          -webkit-transition: transform 1s cubic-bezier(0.9,0,0.5,1.2);
          -moz-transition: transform 1s cubic-bezier(0.9,0,0.5,1.2);
          -ms-transition: transform 1s cubic-bezier(0.9,0,0.5,1.2);
          -o-transition: transform 1s cubic-bezier(0.9,0,0.5,1.2);
        }
      }
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        @media #{$Ipad} {
          width: 100%; 
          flex-direction: column;
        }
        li {
          list-style: none;
          padding: 0 28px;
          @media #{$Laptop} {
            padding: 0 15px;
          }
          @media #{$Ipad} {
            padding: 0;
            width: 100%;
            border-bottom: 1px solid $LinkWater;
          }
          &:first-child {
            @media #{$Ipad} {
              border-top: 1px solid $LinkWater;
            }
          }
          .link {
            position: relative;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            padding: 0;
            text-decoration: none;
            color: #03111b;
            transition: all 0.3s ease-in-out;
            cursor: pointer;
            @media #{$Ipad} {
              width: 100%;
              display: block;
              padding: 15px 20px; 
              font-weight: 600;
            }
            &:hover,
            &.active {
              color: $primary;
              @media #{$Ipad} {
                background-color: $primary;
                color: $White;
              }
            }
          }
        }
      }
    }
    &_cta {
      flex: 0 0 250px;
      text-align: right;
      @media #{$Laptop} {
        flex: 0 0 220px;
      }
      @media #{$Ipad} {
        flex: 0 0 auto;
        margin-left: auto;
      }
      .btn {
        padding-left: 36px;
        padding-right: 36px;
        @media #{$LaptopLg} {
          padding-left: 26px;
          padding-right: 26px;
        }
        @media #{$Laptop} {
          padding: 14px 20px; 
          font-size: 18px;
          line-height: 22px;
          border-radius: 8px;
        }
        @media #{$Ipad} {
          padding: 8px 10px;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
    .menu-icon {
      background-color: transparent;
      padding: 0;
      border: 0;
      display: none;
      max-width: 38px;
      flex: 0 0 38px;
      @media #{$Ipad} {
        display: block;
        margin-left: 10px;
      }
    }
  }
}
@keyframes closemenubtn {
	from { transform: translateX(200px) rotate(360deg) ; -webkit-transform: translateX(200px) rotate(360deg) ; -moz-transform: translateX(200px) rotate(360deg) ; -ms-transform: translateX(200px) rotate(360deg) ; -o-transform: translateX(200px) rotate(360deg) ; }
	to { transform: translateX(0px) rotate(0deg) ; -webkit-transform: translateX(0px) rotate(0deg) ; -moz-transform: translateX(0px) rotate(0deg) ; -ms-transform: translateX(0px) rotate(0deg) ; -o-transform: translateX(0px) rotate(0deg) ; }
}