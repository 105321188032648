@import '../../Config/variables.scss';

.approach-tag {
    background: $White;
    box-shadow: 0px 4px 10px rgba($color: $GrayTwo, $alpha: 0.28);
    border-radius: 16px;
    padding: 20px;
    margin: 0;
    display: inline-flex;
    align-items: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: rgba($color: $PrussianBlue, $alpha: 0.40);
    position: absolute;
    z-index: 10;
    filter: grayscale(1);
    transition: all 0.3s ease-in-out;
    @media #{$Ipad} {
        font-size: 16px;
        line-height: 24px;
        padding: 10px;
    }
    &.active {
        color: $PrussianBlue; 
        filter: grayscale(0);
    }
    .icon {
        flex: 0 0 auto;
        margin-right: 14px;
        @media #{$Ipad} {
            margin-right: 10px;
        }
    }
}