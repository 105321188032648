@import "./../../Config/variables.scss";
.our-approach-sec {
  display: block;
  padding: 89px 0 130px;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  overflow: hidden;
  @media #{$LaptopLg} {
    padding: 80px 0 100px;
  }
  @media #{$Laptop} {
    padding: 80px 0 80px;
  }
  @media #{$Ipad} {
    padding: 60px 0;
  }
  @media #{$PhoneL} {
    padding: 40px 0;
  }
  .pageTitle {
    margin-bottom: 80px;
    @media #{$LaptopLg} {
      margin-bottom: 60px;
    }
    @media #{$Laptop} {
      margin-bottom: 50px;
    }
    @media #{$Ipad} {
      margin-bottom: 40px;
    }
    @media #{$PhoneL} {
      margin-bottom: 30px;
    }
  }
  .progress-cricle-row {
    display: flex;
    justify-content: center;
    @media #{$PhoneL} {
      height: 360px;
    }
    @media #{$PhoneSm} {
      height: 290px;
    }
  }
  .progress-circle {
    position: relative;
    width: 640px;
    height: 760px;
    margin: 0 auto;
    padding: 60px 0;
    @media #{$Ipad} {
      padding: 40px 0;
      height: 720px;
    }
    @media #{$PhoneL} {
      transform: scale(0.5);
      transform-origin: top;
    }
    @media #{$PhoneSm} {
      transform: scale(0.4);
    }
    .planing-card {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .design-card {
      top: 165px;
      right: -52px;
      @media #{$Ipad} {
        right: -40px;
      }
    }
    .development-card {
      bottom: 152px;
      right: -91px;
      @media #{$Ipad} {
        right: -40px;
      }
    }
    .deployment-card {
      left: 50%;
      transform: translateX(-50%);
      bottom: -1px;
      @media #{$Ipad} {
        bottom: 10px;
      }
    }
    .testing-card {
      bottom: 152px;
      left: -86px;
      @media #{$Ipad} {
        left: -40px;
      }
    }
    .planningtwo-card {
      top: 165px;
      left: -99px;
      @media #{$Ipad} {
        left: -40px;
      }
    }
    .ap-left-arrow {
      position: absolute;
      top: 50%;
      left: -230px;
      transform: translate(0, -50%);
    }
    .ap-right-arrow {
      position: absolute;
      top: 50%;
      right: -230px;
      transform: translate(0, -50%);
    }
    .ap-logo-cirrcle {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
    }
    .border-circle {
      position: relative;
      z-index: 9;
      width: 640px;
      height: 640px;
      transform: rotate(-90deg);
      circle {
        width: 100%;
        height: 100%;
        fill: none;
        stroke: $GrayOne;
        stroke-width: 3;
        &.orange {
          stroke: $primary;
          stroke-dasharray: 2000px;
        }
        &.white {
          stroke: #f5faff;
          stroke-dasharray: 6 10;
          stroke-width: 5px;
        }
      }
    }
  }
}
