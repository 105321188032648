@import '../../Config/variables.scss';

.hmTriodec {
    background-color: $White;
    padding: 92px 0 101px;
    @media #{$LaptopLg} {
        padding: 65px 0;
    }
    @media #{$Ipad} {
        padding: 60px 0;
    }
    @media #{$PhoneSm} {
        padding:40px 0;
    }
    .triodecInner {
        background: $AliceBlue;
        border-radius: 24px;
        padding: 20px 20px 20px 44px;
        display: flex;
        align-items: center;
        @media #{$Ipad} {
            padding: 20px;
        }
        @media #{$PhoneL} {
            flex-wrap: wrap;
        }
        .triodecContent {
            width: calc(100% - 375px);
            padding-right:15px;
            @media #{$Ipad} {
                width: calc(100% - 235px);
            }
            @media #{$PhoneL} {
                width: 100%;
                padding: 0;
                margin-bottom: 10px;
            }
            h2 {
                margin-bottom: 21px;
                @media #{$Ipad} {
                    margin-bottom: 15px;
                }
            }
            p {
                max-width: 753px;
                margin-bottom: 46px;
                @media #{$Ipad} {
                    margin-bottom: 30px;
                }
                @media #{$PhoneL} {
                    margin-bottom: 20px;
                }
            }
            .triodecServices {
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    li {
                        display: flex;
                        align-items: center;
                        margin: 0 16px 16px 0;
                        @media #{$Ipad} {
                            margin: 0 10px 10px 0;
                        }
                    }
                }
            }
        }
        .triodecImg {
            width: 375px;
            max-width: 375px;
            flex: 0 0 375px;
            padding-left:15px;
            @media #{$Ipad} {
                width: 235px;
                max-width: 235px;
                flex: 0 0 235px;
            }
            @media #{$PhoneL} {
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;
                padding: 0;
            }
            img {
                width: 100%;
            }
        }
    }
}   