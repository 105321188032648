@import '../../Config/variables.scss';

.triodecTeg {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: $PrussianBlue;
    background: $White;
    box-shadow: 0px 4px 10px rgba(224, 238, 251, 0.28);
    border-radius: 25px; 
    padding: 7px 15px;
    @media #{$Ipad} {
        font-size: 14px;
        line-height: 24px;
        padding: 7px 10px;
    }
    span {
        display: block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        margin-right: 12px;
        @media #{$Ipad} {
            margin-right: 6px;
        }
    }
}