@import '../../Config/variables.scss';

.hmTechnologies {
    padding: 100px 0 90px;
    background: $GrayBG;
    @media #{$LaptopLg} {
        padding: 65px 0;
    }
    @media #{$Ipad} {
        padding: 60px 0;
    }
    @media #{$PhoneSm} {
        padding:40px 0;
    }
    .pageTitle {
        margin-bottom: 50px;
        @media #{$PhoneL} {
            margin-bottom: 40px;
        }
    }
    .technologiesTabs {
        display: flex;
        align-items: center;
        @media #{$PhoneL} {
            flex-wrap: wrap;
        }

        .technologiesTabsBtn {
            width: 308px;
            max-width:308px;
            flex: 0 0 308px;
            position: relative;
            z-index: 1;
            @media #{$Ipad} {
                width: 250px;
                max-width:250px;
                flex: 0 0 250px;
            }
            @media #{$PhoneL} {
                width: 100%;
                max-width:100%;
                flex: 0 0 100%;
                margin-bottom: 0px;
                overflow-x: auto;
            }
            .nav-pills {
                background: $White;
                box-shadow: 0px 4px 63px $PattensBlue;
                border-radius: 24px;
                padding: 32px 20px 16px; 
                @media #{$Ipad} {
                    padding: 15px;
                }
                @media #{$PhoneL} {
                    display: flex;
                    flex-direction: inherit !important;
                    width: 644px;
                    padding: 10px;
                    border-radius: 10px;
                }
                .nav-item {
                    margin-bottom: 10px;
                    @media #{$PhoneL} {
                        margin: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .nav-link {
                        font-weight: 400;
                        font-size: 24px;
                        line-height: 34px;
                        color: $SlateGrey;
                        padding:18px 20px;
                        border-radius: 12px; 
                        @media #{$LaptopLg} {
                            font-size: 20px;
                            line-height: 30px;
                            padding: 15px 20px;
                        }
                        @media #{$Ipad} {
                            font-size: 18px;
                            line-height: 30px;
                            padding: 20px 30px;
                        }
                        @media #{$PhoneL} {
                            font-size: 16px;
                            line-height: 16px;
                            padding: 10px 15px;
                            border-radius: 8px;
                        }
                        &.active {
                            background: $primary; 
                            color: $White;
                        }
                    }
                }
            }
        }
        .technologiesTabsContent {
            width:calc(100% - 288px);
            background: $AliceBlueLight;
            border-radius: 24px;
            margin-left: -20px;
            @media #{$Ipad} {
                width:calc(100% - 230px);
            }
            @media #{$PhoneL} {
                width: 100%;
                max-width:100%;
                flex: 0 0 100%;
                border-radius: 0 0 24px 24px;
                margin: 0;
            }
            .tab-content {
                padding: 61px 10px 67px 76px;
                @media #{$Ipad} {
                    padding: 30px 10px 30px 45px;
                }
                @media #{$PhoneL} {
                    padding: 15px;
                }
                .tab-pane {
                    h4 {
                        margin-bottom: 25px;
                        @media #{$LaptopLg} {
                            margin-bottom: 20px;
                        }
                        @media #{$Ipad} {
                            margin-bottom: 15px;
                        }
                    }
                    p {
                        margin-bottom: 20px;
                        max-width: 935px;
                        @media #{$LaptopLg} {
                            margin-bottom: 15px;
                        }
                        @media #{$Ipad} {
                            margin-bottom: 15px;
                        }
                    }
                    .logo-icons {
                        padding: 0;
                        margin: 62px 0 0 0;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        list-style: none;
                        @media #{$LaptopLg} {
                            margin: 42px 0 0 0;
                        }
                        @media #{$Ipad} {
                            margin: 30px 0 0 0;
                        }
                        li {
                            padding-right:30px; 
                            @media #{$PhoneL} {
                                padding-right:20px;
                            }
                            &:last-child {
                                padding-right: 0;
                            }
                            img {
                                max-height: 34px;
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}