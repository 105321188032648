$primary:  #0466C8 !default; 
$DodgerBlue: #429DF9;
$MayaBlue:#55ADFB;
$DodgerBlue:#3097FF;
$SlateGrey: #758194 ; 
$White: #FFFFFF;
$AliceBlue: #F7FBFF;

$LinkWater: #CDD6E2;
$DarkBlack: #03111B;
$PrussianBlue: #02124E;
$GrayBG: #F3F8FF;
$AliceBlue:#EEF4FE; 
$PattensBlue:#D8E5F1;
$AliceBlueLight: #E8F0FD;
$AliceBlueLight2: #F1F6FF;
$SapphireBlue:#031C54;
$Lavender:#EDF4FA;
$LinkWater:#C6CFDE;
$GrayColor: #6B7598;
$GrayOne:#BCD0DE;
$GrayTwo:#e0eefb;
$LightPattensBlue:#E6F7F7;


/** bootstrap **/
$container-max-widths: (
  sm: 1350px, 
) !default;
$container-padding-x: 30px !default;
$font-family-sans-serif: 'Inter', sans-serif !default;
$font-family-monospace: 'Inter', sans-serif !default;
$body-bg: $GrayBG !default;
$body-color: $SlateGrey !default;
$btn-padding-y: 17px !default;
$btn-padding-x:  45px !default;
$btn-font-size: 20px !default;    
$btn-line-height: 24px !default;
$btn-font-weight: 500 !default;
$btn-border-radius: 10px !default;

$LaptopLg: 'only screen and (max-width: 1720px)';
$Laptop: 'only screen and (max-width: 1199px)';
$Ipad: 'only screen and (max-width : 991px)'; 
$PhoneL: 'only screen and (max-width: 767px)';
$PhoneSm: 'only screen and (max-width: 575px)';  