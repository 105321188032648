@import '../../Config/variables.scss';

.toolsBox {
    display: flex;
    align-items: center;
    background: $White;
    box-shadow: 0px 4px 13px $Lavender;
    border-radius: 12px;
    padding: 12px 22px; 
    min-height: 68px;
    @media #{$Ipad} {
        padding: 10px 15px; 
    }
    img {
        max-width: 34px;
    }
    span {
        font-weight: 600;
        font-size: 20px;
        line-height: normal;
        color: $SapphireBlue;
        display: block;
        margin-left: 10px;
        @media #{$Ipad} {
            font-size: 18px;
        }
    }
}