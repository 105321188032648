@import '../../Config/variables.scss';

.hmServices {
    display: block;
    padding: 120px 0 91px;
    position: relative;
    height: 100vh;
    overflow: hidden;
    z-index: 2;
    background: linear-gradient(180deg, $White 0%, rgba(255, 255, 255, 0) 100%);
    @media #{$LaptopLg} {
        padding: 90px 0 71px;
    }
    @media #{$Ipad} {
        padding: 60px 0;
        height: auto; 
    }
    @media #{$PhoneSm} {
        padding:40px 0;
    }
    
    .servicesInner {
        display: flex;
        align-items: flex-start;
        margin: 0 -15px;  
        position: relative;
        @media #{$Ipad} {
            flex-wrap: wrap;
        }
        .pageTitle {
            max-width: 420px;
            width: 420px;
            flex: 0 0 420px;
            padding: 0 15px; 
            @media #{$LaptopLg} {
                max-width: 400px;
                width: 400px;
                flex: 0 0 400px;
            }
            @media #{$Laptop} {
                max-width: 340px;
                width: 340px;
                flex: 0 0 340px; 
            }
            @media #{$Ipad} {
                max-width: 100%;
                width: 100%;
                flex: 0 0 100%;
                margin-bottom: 30px;
                text-align: center;
                position: static;
            }
            p {
                margin-bottom: 35px;
                @media #{$Ipad} {
                    margin-bottom: 20px;
                }
            }
        }
        .servicesWrap {
            width: calc(100% - 420px);
            padding: 0 15px;
            display: flex;
            align-items: center;
            position: absolute;
            right: 15px;
            @media #{$LaptopLg} {
                width: calc(100% - 400px);
            }
            @media #{$Laptop} {
                width: calc(100% - 340px);
            }
            @media #{$Ipad} {
                width: 100%;
                position: static;
            }
            @media #{$PhoneSm} {
                flex-wrap:wrap;
            }
            .servicesListLeft {
                width: 50%;
                max-width: 50%;
                flex: 0 0 50%;
                padding-right: 15px;
                position: relative;
                @media #{$Ipad} {
                    padding-right: 7px;
                }
                @media #{$PhoneSm} {
                    width: 100%;
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding-right: 0px;
                    margin-bottom: 15px;
                }
            }
            .servicesListRight {
                width: 50%;
                max-width: 50%;
                flex: 0 0 50%;
                padding-left: 15px;
                position: relative;
                @media #{$Ipad} {
                    padding-left: 7px;
                }
                @media #{$PhoneSm} {
                    width: 100%;
                    max-width: 100%;
                    flex: 0 0 100%;
                    padding-left: 0px;
                }
            }
        }
    }
}
// .animationCard, .animationCardRight {
//     position: absolute; 
//   width: 100%;
// }