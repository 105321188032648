@import '../../Config/variables.scss';

.hmVision {
    background: $GrayBG;
    padding: 100px 0;
    position: relative;
    overflow: hidden;
    @media #{$LaptopLg} {
        padding: 65px 0;
    }
    @media #{$PhoneL} {
        padding:0 0 50px;
    }
    .visionImg {
        width: 100%;
        img {
            width: 65%;
            border-radius: 24px;
            @media #{$LaptopLg} {
                height: 450px;
                object-fit: cover;
            }
            @media #{$Ipad} {
                width: 85%;
            }
            @media #{$PhoneL} {
                width: 100%;
                height: auto;
            }
        }
    }
    .visionInner {
        position: absolute;
        top: 50%;
        transform: translate(0 ,-50%);
        right: 0;
        width: 100%;
        @media #{$PhoneL} {
            position: static;
            transform: inherit;
            left: 0;
            right: 0;
            text-align: center;
        }
        .visionCard {
            background: rgba(255, 255, 255, 0.9);
            backdrop-filter: blur(18px);
            border-radius: 50px;
            padding: 60px 65px;
            max-width: 886px;
            margin-left: auto;
            position: relative;
            @media #{$LaptopLg} {
                padding: 36px 45px;
            }
            @media #{$Laptop} {
                padding: 50px;
                max-width: 700px;
            }
            @media #{$Ipad} {
                padding: 30px;
                max-width: 550px;
            }
            @media #{$PhoneL} {
                margin: -100px auto 0;
            }
            @media #{$PhoneSm} {
                margin: -50px auto 0;
                padding: 20px;
            }
            .curveImg {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: auto;
                img {
                    height: 100%;
                    width: auto;
                }
                @media #{$PhoneL} {
                    display: none;
                }
            }
            .visionContent {
                h2 {
                    margin-bottom: 12px;
                }
                p {
                    margin-bottom: 13px;
                }
                a {
                    margin-top: 23px;
                    @media #{$Ipad} {
                        margin-top: 15px;
                    }
                }
                
            }
        }
    }
}