@import '../../Config/variables.scss';

.servicesCard {
    margin-bottom: 30px;
    &:last-child {
        margin-bottom: 0;
    }
    @media #{$Ipad} {
        margin-bottom: 15px;
    }
    .servicesCardItem {
        border: 1px solid $LinkWater;
        border-radius: 10px;
        padding: 31px 27px;
        transition: all 0.3s ease-in-out;
        @media #{$Laptop} {
            padding: 21px 17px;
        }
        &:hover {
            background-color: $White;
            border-color:$White ;
            box-shadow: 2px 20px 25px rgba(217, 216, 240, 0.3);
        }
        .servicesCardIcon {
            margin-bottom: 31px;
            @media #{$Laptop} {
                margin-bottom: 20px;
            }
            svg {
                @media #{$Laptop} {
                    width: 65px;
                    height: 65px;
                }
            }
        }
        .servicesCardContent {
            p {
                margin-bottom: 0;
            }
        }
    }
}