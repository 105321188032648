@import '../../Config/variables.scss';

.hmTools {
    background-color: $White;
    padding: 100px 0;
    @media #{$LaptopLg} {
        padding: 65px 0;
    }
    @media #{$Ipad} {
        padding: 60px 0;
    }
    @media #{$PhoneSm} {
        padding:40px 0;
    }
    .toolsInner {
        background: $GrayBG;
        border-radius: 24px;
        padding: 60px 0;
        position: relative;
        overflow: hidden;
        @media #{$LaptopLg} {
            padding: 50px 0;
        }
        @media #{$Ipad} {
            padding: 40px 0;
        }
        &::after {
            content: "";
            position: absolute;
            left: 0;
            width: 401px;
            height: 367px;
            top: 0;
            background: url(../../Images/tools-bg-img-top.svg) center no-repeat;
            background-size: cover;
            z-index: 1;
            @media #{$PhoneL} {
                width: 290px;
                height: 250px;
            }
        }
        &::before {
            content: "";
            position: absolute;
            right: 0;
            width: 401px;
            height: 367px;
            bottom: 0;
            background: url(../../Images/tools-bg-img-bottom.svg) center no-repeat;
            background-size: cover;
            z-index: 1;
            @media #{$PhoneL} {
                width: 290px;
                height: 250px;
            }
        }
        .toolsInnerWrap {
            position: relative;
            z-index: 2;
            .pageTitle {
                margin-bottom: 60px;
                text-align: center;
                padding: 0 15px;
                @media #{$Ipad} {
                    margin-bottom: 40px;
                }
                
                h2 {
                    margin-bottom: 12px;
                }
            }
            .toolsCardRow {
                padding: 0 80px 40px;
                @media #{$LaptopLg} {
                    padding: 0 50px 30px;
                }
                @media #{$Ipad} {
                    padding: 0 20px 30px;
                }
                ul {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    list-style: none;
                    margin: 0 -15px;
                    padding: 0;
                    @media #{$LaptopLg} {
                        margin: 0 -10px;
                    }
                    @media #{$PhoneL} {
                        margin: 0 -5px;
                    }
                    li {
                        padding: 0 10px 27px;
                        @media #{$LaptopLg} {
                            padding: 0 10px 20px;
                        }
                        @media #{$PhoneL} {
                            padding: 0 5px 15px;
                        }
                    }
                }
            }
            .toolsBtn {
                width: 100%;
                text-align: center;
            }
        }
    }
}