@import "../../Config/variables.scss";

.footer {
  padding-top: 128px;
  @media #{$LaptopLg} {
    padding-top: 65px;
  }
  @media #{$Ipad} {
    padding-top: 70px;
  }
  @media #{$PhoneL} {
    padding-top: 50px;
  }
  .footerInner {
    .footerTop {
      padding-bottom: 50px;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      @media #{$PhoneL} {
        padding-bottom: 30px;
      }
      .footerDetails {
        width: 487px;
        max-width: 487px;
        flex: 0 0 487px;
        @media #{$Ipad} {
          width: 300px;
          max-width: 300px;
          flex: 0 0 300px;
        }
        @media #{$PhoneL} {
          width: 100%;
          max-width: 100%;
          flex: 0 0 100%;
          margin-bottom: 30px;
        }
        .footerLogo {
          margin-bottom: 39px;
          @media #{$LaptopLg} {
            margin-bottom: 30px;
          } 
          @media #{$Ipad} {
            margin-bottom: 20px;
          }
          a {
            display: inline-block;
            img {
              @media #{$LaptopLg} {
                max-width: 180px;
              } 
            }
          }
        }
        .footerSocial {
          margin-bottom: 54px;
          @media #{$LaptopLg} {
            margin-bottom: 40px;
          }
          @media #{$Ipad} {
            margin-bottom: 20px;
          }
          ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: center;
            li {
              padding-right: 27px;
              @media #{$LaptopLg} {
                padding-right: 20px;
              } 
              @media #{$Ipad} {
                padding-right: 18px;
              }
              &:last-child {
                padding-right: 0;
              }
              a {
                background: linear-gradient(
                  318.7deg,
                  $primary 17.42%,
                  $DodgerBlue 104.66%
                );
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transition: all 0.3s ease-in-out;
                &:hover {
                  background: linear-gradient(
                    160.1deg,
                    $primary 17.42%,
                    $DodgerBlue 104.66%
                  );
                }
              }
            }
          }
        }
        .footerEamil {
          margin-bottom: 56px;
          @media #{$LaptopLg} {
            margin-bottom: 40px;
          }
          @media #{$Ipad} {
            margin-bottom: 20px;
          }
          p {
            margin-bottom: 8px;
            color: $GrayColor;
            @media #{$Ipad} {
              font-size: 14px;
            }
          }
          a {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 32px;
            line-height: 40px;
            color: $primary;
            text-decoration: none;
            @media #{$LaptopLg} {
              font-size: 22px;
            } 
            @media #{$PhoneSm} {
              font-size: 20px;
            }
            img {
              margin-top: 4px;
              margin-right: 20px;
              @media #{$LaptopLg} { 
                margin-right: 12px;
                max-width: 24px;
              } 
              @media #{$Ipad} {
                margin-right: 10px; 
              }
            }
          }
        }
        .footerNewsletter {
          border-top: 1px solid $LinkWater;
          padding-top: 38px;
          margin-bottom: 48px;
          @media #{$LaptopLg} {
            margin-bottom: 40px;
          }
          @media #{$Ipad} {
            padding-top: 20px;
            margin-bottom: 20px;
          }
          h2 {
            font-size: 42px;
            line-height: 51px;
            color: $SapphireBlue;
            margin-bottom: 11px;
            @media #{$LaptopLg} {
              font-size: 34px;
              line-height: 40px;
            }
            @media #{$Ipad} {
              font-size: 32px;
              line-height: 38px;
            }
            @media #{$PhoneSm} {
              font-size: 28px;
              line-height: normal;
            }
          }
          p {
            color: $GrayColor;
            margin-bottom: 38px;
            @media #{$Ipad} {
              margin-bottom: 20px;
            }
          }
          .form-check {
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            .form-control {
              background: $GrayBG;
              border: 1px solid $SlateGrey;
              border-radius: 10px;
              height: 60px;
              font-weight: 400;
              font-size: 20px;
              line-height: 30px;
              color: $GrayColor; 
              padding: 0 21px;
              width: calc(100% - 62px);
              margin-right: 19px;
              @media #{$LaptopLg} {
                margin-right: 15px;
                height: 50px;
                font-size: 16px;
                padding: 0 15px;
              } 
              &:focus {
                outline: none;
                box-shadow: inherit;
              }
              &::-webkit-input-placeholder {
                color: $GrayColor;
                opacity: 1;
              }
              &::-moz-placeholder {
                color: $GrayColor;
                opacity: 1;
              }
              &:-ms-input-placeholder {
                color: $GrayColor;
                opacity: 1;
              }
              &:-moz-placeholder {
                color: $GrayColor;
                opacity: 1;
              }
            }
            .btn-send {
              border-radius: 10px;
              width: 62px;
              height: 60px;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0;
              transition: all 0.3s ease-in-out;
              @media #{$LaptopLg} {
                height: 50px;
              }
            }
          }
        }
        .footerMenu {
          h6 {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: $SapphireBlue;
            margin-bottom: 12px;
          }
          ul {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style: none;
            li {
              padding-right: 50px;
              @media #{$Ipad} {
                padding-right: 25px;
              }
              @media #{$PhoneSm} {
                padding-right: 20px;
              }
              &:last-child {
                padding-right: 0;
              }
              a {
                font-weight: 400;
                font-size: 18px;
                line-height: 30px;
                color: $SlateGrey;
                text-decoration: none;
                transition: all 0.3s ease-in-out;
                @media #{$Ipad} {
                  font-size: 14px;
                }
                &:hover {
                  color: $primary;
                }
              }
            }
          }
        }
      }
      .footerForm {
        width: calc(100% - 487px);
        margin-left: auto;
        padding-left: 30px;
        @media #{$Ipad} {
          width: calc(100% - 300px);
        }
        @media #{$PhoneL} {
          width: 100%;
          max-width: 100%;
          flex: 0 0 100%;
          padding: 0;
        }
        .footerFormWrap {
          background: $primary;
          border-radius: 10px;
          max-width: 645px;
          padding: 48px 52px;
          margin-left: auto;
          @media #{$LaptopLg} {
            max-width: 565px;
            padding: 40px 42px;
          }
          @media #{$Laptop} {
            padding: 25px;
          }
          @media #{$Ipad} {
            padding: 15px;
          }
          @media #{$PhoneL} {
            width: 100%;
            max-width: 100%;
          }
          .footerFormText {
            font-weight: 500;
            font-size: 32px;
            line-height: 40px;
            color: $White;
            margin-bottom: 37px;
            max-width: 453px;
            @media #{$LaptopLg} {
              font-size: 24px;
              line-height: 32px;
              max-width: 395px;
              margin-bottom: 25px;
            }
            @media #{$Ipad} {
              font-size: 22px;
              line-height: 30px;
              margin-bottom: 20px;
              max-width: 290px;
            }
          }
          .form-check {
            padding: 0;
            margin: 0 0 30px;
            &:last-child {
              margin-bottom: 0;
            }
            .form-control {
              background: $AliceBlue;
              border: 1px solid $MayaBlue;
              border-radius: 10px;
              height: 60px;
              font-weight: 400;
              font-size: 20px;
              line-height: 30px;
              color: $GrayColor;
              padding: 15px 18px;
              @media #{$Ipad} {
                padding: 15px;
                height: 50px;
                font-size: 16px;
              }
              &:focus {
                outline: none;
                box-shadow: inherit;
              }
              &::-webkit-input-placeholder {
                color: $GrayColor;
                opacity: 1;
              }
              &::-moz-placeholder {
                color: $GrayColor;
                opacity: 1;
              }
              &:-ms-input-placeholder {
                color: $GrayColor;
                opacity: 1;
              }
              &:-moz-placeholder {
                color: $GrayColor;
                opacity: 1;
              }
            }
            .btn-contact {
              background: $DodgerBlue;
              border-radius: 10px;
              padding: 15px 46px;
              font-weight: 400;
              font-size: 20px;
              line-height: 30px;
              color: $White;
              transition: all 0.3s ease-in-out;
              @media #{$LaptopLg} {
                padding: 14px 26px;
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
              }
              @media #{$Ipad} {
                font-size: 16px;
                padding: 9px 35px;
              }
              &:hover {
                background: $MayaBlue;
              }
            }
            textarea.form-control {
              height: 140px;
              resize: none;
              @media #{$Ipad} {
                height: 130px;
              }
            }
          }
        }
      }
    }

    .footerCopyRight {
      display: flex;
      align-items: center;
      border-top: 1px solid $LinkWater;
      padding: 11px 0 30px;
      justify-content: space-between;
      @media #{$Ipad} {
        padding: 11px 0 20px;
      }
      @media #{$PhoneSm} {
        flex-wrap: wrap;
      }
      p {
        margin: 0;
        font-size: 16px;
        padding-right: 15px;
        @media #{$Ipad} {
          font-size: 14px;
        }
        @media #{$PhoneSm} {
          width: 100%;
          order: 2;
          padding: 0;
          text-align: center;
        }
      }
      .footerCopyRightMenu {
        padding-left: 15px;
        @media #{$PhoneSm} {
          width: 100%;
          order: 1;
          padding-left: 0;
          margin-bottom: 15px;
        }
        ul {
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;
          list-style: none;
          @media #{$PhoneSm} {
            justify-content: center;
          }
          li {
            padding-left: 25px;
            &:first-child {
              padding-left: 0;
            }
            a {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: $SlateGrey;
              text-decoration: none;
              transition: all 0.3s ease-in-out;
              @media #{$Ipad} {
                font-size: 14px;
              }
              &:hover {
                color: $primary;
              }
            }
          }
        }
      }
    }
  }
}
